import React from "react";
import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const Grunderwerbsteuer = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="Die Grunderwerbsteuer – was ist das und wie viel macht sie aus?" showCalc={false} />
            <Article>
                <p>
                    Einen großen Teil der{" "}
                    <Link to="/artikel/kaufnebenkosten/" target="_blank" rel="noreferrer noopener">
                        Nebenkosten
                    </Link>
                    , die du beim{" "}
                    <Link to="/artikel/hauskauf/" target="_blank" rel="noreferrer noopener">
                        Haus
                    </Link>
                    - oder Wohnungskauf bezahlen musst, machen Steuern aus. In diesem Artikel erklären wir dir kurz und
                    bündig, was es mit der Grunderwerbsteuer auf sich hat und wie diese berechnet wird.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Was versteht man unter der Grunderwerbsteuer?</h2>
                <p>
                    Die Grundsteuer bezieht sich (wenig überraschend) auf den Erwerb von Grundstücken innerhalb
                    Österreichs - und zwar unabhängig davon, ob der neue Eigentümer für die Übernahme etwas bezahlen
                    musste. Sie ist also auch dann fällig, wenn das betreffende Haus bzw. Grundstück durch eine
                    Schenkung in deinen Besitz übergeht. Steuerpflichtig bist du ab dem Zeitpunkt, an dem das Geschäft
                    durch das Unterzeichnen des Kaufvertrages rechtskräftig ist. Die Eintragung ins{" "}
                    <Link to="/artikel/grundbuch-kosten/" target="_blank" rel="noreferrer noopener">
                        Grundbuch
                    </Link>{" "}
                    ist für die Grundsteuer ebenfalls nebensächlich.
                </p>
                <hr />

                <h2>Wie wird die Höhe der Grunderwerbsteuer festgelegt?</h2>
                <p>
                    Bemessungsgrundlage der Grunderwerbsteuer ist üblicherweise der Wert der Gegenleistung, die die
                    Käufer:in für den Erwerb des jeweiligen Grundstücks aufbringt. Dazu zählen nicht nur sämtliche
                    Zahlungen an die Verkäufer:in, sondern auch die Entrichtung von Entgelten an Dritte, die auf diese
                    Weise beispielsweise dazu bewegt wurden, von ihrem Kaufrecht zurückzutreten. Wenn du ein Grundstück
                    durch eine Schenkung erhältst oder es von einer verstorbenen Person an dich vererbt wird, so wird
                    sein Wert als Bemessungsgrundlage herangezogen. Eine ungefähre Vorstellung von diesem Wert erhält
                    man durch den{" "}
                    <a
                        href="https://www.bmf.gv.at/themen/steuern/immobilien-grundstuecke/grunderwerbsteuer/bemessungsgrundlage.html"
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        Grundstückswertmesser des Bundesministeriums für Finanzen
                    </a>
                    , der ähnlich funktioniert wie unser{" "}
                    <Link to="/dienstleistungen/kreditrechner/" target="_blank" rel="noreferrer noopener">
                        Kreditrechner
                    </Link>
                    .
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
            </Article>

            <BreadcrumbList page={"grunderwerbsteuer"}></BreadcrumbList>
            <ArticleStructuredData
                page={"grunderwerbsteuer"}
                heading={"Die Grunderwerbsteuer – was ist das und wie viel macht sie aus?"}
            />
        </Layout>
    );
};

export default Grunderwerbsteuer;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "page.artikel.grunderwerbsteuer", "mortgageCalc"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
